/* For the BackgroundedContainer.tsx */
@keyframes fade-in {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* For MainSearch.tsx */
.mainSearchInfo {
    opacity: 1;
    transition: opacity 0.5s
}

.mainSearchInfo.hide {
    opacity: 0;
}


body{
    /* https://stackoverflow.com/a/65871351 */
    overscroll-behavior-y: none;
}